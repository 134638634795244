<template>
  <div class="planNum_list">
    <GlobalInfoBar title="机型列表"  subtitle="理赔时通过注册订单子险种名称区分：带有“讯捷”的子险种名称使用讯捷通道的协议价格,其他子险种名称按“亚丁”亚丁协议价处理。卖教售根据订单卖教售标识使用卖教售协议价"/>
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="品牌名称:" slot="operation">
        <el-select
          v-model="brand_id"
          placeholder="请品牌名称"
          clearable
          @change="handleChange"
        >
          <el-option
            v-for="item in options"
            :key="item.brand_id"
            :label="item.brand"
            :value="item.brand_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button size="small" @click="addPlanDia('export')" type="primary" icon="el-icon-plus">批量导入机型</el-button>
        <el-button size="small" @click="addPlanDia('edit')" type="primary" icon="el-icon-plus">批量改价</el-button>
        <el-button size="small" @click="addPlan()" type="primary" icon="el-icon-plus">新增机型</el-button>
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="PlanIpageNum"
      :total="PlanItotal"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="操作" slot="operat" align="center">
        <template slot-scope="{ row }">
          <el-button @click="editPlan(row,'edit')" type="text" class="mr_sty">修改</el-button>
          <el-button @click="deleteDis(row)" type="text" class="btn_delete">删除</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="marginRight:12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载{{typeDia===1?'模板表格':'全部机型价格'}}</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content">请上传.xls或.xlsx格式的excel文件，大小10M内</span>
        </div>
        <div class="step2-content">
          <el-upload ref="upload"
            :limit="1"
            :auto-upload="false"
            :http-request="uploadFile"
            accept='.xls,.xlsx'
            action="customize">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确认导入</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "planNum_list",
  data() {
    return {
      // 弹窗
      dialogVisible:false,
      dialogTitle:"",
      typeDia:'',
      //列表
      initData: null,
      options:[],
      end_time: "",
      beg_time: "",
      formItemList: [
        {
          key: "model_name",
          type: "input",
          labelName: "机型名称:",
          placeholder: "请输入机型名称"
        },
        { slotName: "operation" },
      ],
      formRules: {},
      tableData: [],
      PlanItotal: 0,
      PlanIpageNum: 1,
      PlanIpageSize: 10,
      model_name: "",
      brand_id:'',
      tableColumns: [
        { label: "机型名称", prop: "model_name" },
        { label: "所属品牌", prop: "brand" },
        { label: "规格", prop: "ram_size" },
        { label: "亚丁协议价格（￥）", prop: "price" },
        { label: "讯捷协议价格（￥）", prop: "price1" },
        { label: "苹果Apr协议价（￥）", prop: "price2" },
        { label: "官网价格（￥）", prop: "official_price" },
        { label: "最后更新时间", prop: "add_time" },
        { slotName: "operat" }
      ]
    };
  },
  mounted() {
    _api.phoneBrandList().then(res => {
        this.options = res.data;
    });
    this.PostList();
  },
  methods: {
    //列表请求
    PostList() {
      const params = {
        page: this.PlanIpageNum,
        limit: this.PlanIpageSize,
        brand_id: this.brand_id,
        model_name: this.model_name,
      };
      _api.phonePriceList(params).then(res => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    //翻页
    handleCurrentChange(val) {
      this.PlanIpageNum = val;
      this.PostList();
    },
    handleChange(val){
      console.log(val)
      this.brand_id = val
    },
    //搜索
    handleConfirm(data) {
      console.log(data, "data");
      this.model_name = data.model_name;
      // this.brand_id = data.brand_id;
      const params = {
        page: 1,
        limit: 10,
        brand_id: this.brand_id,
        model_name: data.model_name
      };
      _api.phonePriceList(params).then(res => {
        this.tableData = res.data;
        this.PlanItotal = res.count;
      });
    },
    addPlan() {
      this.$router.push({path:'editAdd',query:{type:'add'}})
    },
    //修改
    editPlan(row, type) {
      this.$router.push({path:'editAdd',query:{type:'edit',id:row.price_id}})
    },
    //删除
    deleteDis(row){
      console.log(row)
      this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _api.delPhonePrice({price_id:row.price_id}).then(res=>{
            if(res.code===1){
              this.$message.success('删除成功')
              this.PostList()
            }else{
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消'
          // });
        });
    },
    // 弹窗
    addPlanDia(type){
      console.log(type)
      this.dialogVisible = true
      if(type==='edit'){
        this.typeDia = '2'
        this.dialogTitle = '批量改价'
      }else{
        this.dialogTitle = '批量导入机型'
        this.typeDia = '1'
      }
    },
    download () {
      _api.downloadPhone({type:this.typeDia}).then(res => {
                console.log(res)
                const content = res
                const blob = new Blob([content])
                const fileName = '全部机型.xls'
                const fileName2 = '全部机型价格修改.xls'
                if ('download' in document.createElement('a')) {
                    // 非IE下载
                    const elink = document.createElement('a')
                    if(this.typeDia==='1'){
                      elink.download = fileName
                    }else{
                      elink.download = fileName2
                    }
                    elink.style.display = 'none'
                    elink.href = URL.createObjectURL(blob)
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
      })
    },
    uploadFile(params){
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      const loading = this.$loading({
            lock: true,
            text: '正在导入数据，请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
            _api.uploadPhone(formData).then(res => {
              if (res.code === 1) {
                loading.close();
                this.$message({
                  type: "success",
                  message: res.msg
                });
                this.PostList()
              } else {
                loading.close();
                this.$message({
                  type: "warning",
                  message: res.msg
                });
              }
            })
        }, 1000);
    },
    handleSuccess(){
       this.dialogVisible = false;
       this.$refs.upload.submit();
    },
    // 弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogClosed() {
      this.dialogVisible = false;
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.planNum_list {
  /deep/ .el-form-item__label{
        width: 80px!important;
    }
  .step{
    .step-title{
      color: #0981FF;
      margin-bottom: 10px;
      padding-left: 6px;
      border-left: 3px solid ;
      border-radius: 1.5px;
    }
    .step-content,.step2-content{
      margin-bottom: 26px;
    }
    .step2-content>div{
      display: flex;
    }
    .step2-content{
      .upload-demo{
      display: flex;
      justify-content: flex-start;
      .el-upload-list{
        width: 200px;
      }
      }

    }
    .tip{
      margin-bottom: 12px;
      .tip-title{
        color: #666666;
        margin-right: 12px;
      }
      .tip-content{
        color: #FF687B;
        font-size: 12px;
      }
    }

  }
  .btn_delete{
    color: #FF687B;
  }
  .tit_color {
    color: #0874e6;
    margin: 0 0 20px 0px;
  }
  .el_form {
    /deep/ .el-form-item__label {
      width: 120px !important;
    }
    /deep/ .el-input__inner {
      width: 360px;
    }
    /deep/ .el-input {
      width: 360px;
    }
  }
  .el_form_r{
    float: right;
    margin-right: 80px;
  }
}
</style>
